import { useEffect, useState } from 'react';
import { useDrag, useDragDropManager } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import type { CinemaicCameraType, DragAsset } from '@radical/canvas-fe-types';
import { Icon, P, Tooltip } from '@radical/ui';

import { useSceneAssets } from '../../scene-assets.state';
import { ItemTypes } from '../../scene-assets.types';
import { DragPreviewTooltip } from '../DragPreviewTooltip';
import classic from './assets/classic.svg';
import midTelephoto from './assets/mid-telephoto.svg';
import standard from './assets/standard.svg';
import superTelephoto from './assets/super-telephoto.svg';
import superWide from './assets/superwide.svg';
import telephoto from './assets/telephoto.svg';
import ultrawide from './assets/ultra-wide.svg';
import wide from './assets/wide.svg';
import './SceneCameraBoard.css';

export const cinematicCameras: Array<CinemaicCameraType & { shortName: string }> = [
  {
    icon: superWide,
    name: 'Super wide',
    mm: '12 mm',
    fov: 12,
    shortName: 'S WIDE',
  },
  {
    icon: ultrawide,
    name: 'Ultra Wide',
    mm: '17 mm',
    fov: 17,
    shortName: 'U WIDE',
  },
  {
    icon: wide,
    name: 'Wide',
    mm: '24 mm',
    fov: 24,
    shortName: 'WIDE',
  },
  {
    icon: standard,
    name: 'Standard',
    mm: '35 mm',
    fov: 35,
    shortName: 'STANDARD',
  },
  {
    icon: classic,
    name: 'Classic',
    mm: '50 mm',
    fov: 50,
    shortName: 'CLASSIC',
  },
  {
    icon: telephoto,
    name: 'Telephoto',
    mm: '85 mm',
    fov: 85,
    shortName: 'TELE',
  },
  {
    icon: midTelephoto,
    name: 'Mid telephoto',
    mm: '105 mm',
    fov: 105,
    shortName: 'MID TELE',
  },
  {
    icon: superTelephoto,
    name: 'Super telephoto',
    mm: '200 mm',
    fov: 200,
    shortName: 'S TELE',
  },
];

if (window.__ENV__['ENABLE_CAMERA_ZOOM'] === true) {
  cinematicCameras.push({ icon: classic, name: 'Zoom', mm: 'zoom', fov: 35, shortName: 'ZOOM' });
}

export const SceneCameraBoard = () => {
  const dragState = useSceneAssets().drag;
  const { setDragging, setPosition } = useSceneAssets();

  return (
    <div className="SceneCameraBoard h-full w-full flex justify-evenly items-center gap-[16px] pointer-events-auto flex-wrap ">
      {cinematicCameras.map((camera) => (
        <CameraBoxWithImage camera={camera} setDragging={setDragging} setPosition={setPosition} key={camera.name} />
      ))}
      <DragPreviewTooltip type={ItemTypes.CAMERA} />
    </div>
  );
};

type CameraBoxWithImageProps = {
  camera: CinemaicCameraType;
  setPosition: (x: number, y: number) => void;
  setDragging: (da: DragAsset) => void;
};

function CameraBoxWithImage({ camera, setPosition, setDragging }: CameraBoxWithImageProps): JSX.Element {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const [dragg, setDragg] = useState<boolean>(false);

  const [{ opacity, isDragging }, drag, preview] = useDrag(() => ({
    type: 'camera',
    // item: { type: 'camera' },
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging();
      document.body.style.cursor = isDragging ? 'grabbing !important' : 'default';
      return { isDragging, opacity: monitor.isDragging() ? 0.4 : 1 };
    },
    item: () => {
      setDragg(true);
      return { type: 'camera' };
    },
    end: () => {
      setDragg(false);
    },
  }));

  useEffect(() => {
    monitor.subscribeToOffsetChange(() => {
      // do stuff like setState, though consider directly updating style through refs for performance
      const offset = monitor.getClientOffset();
      // const distance = monitor.getDifferenceFromInitialOffset();
      if (offset) setPosition(offset.x, offset.y);
      // if (distance) setDistance({ x: distance.x, y: distance.y });
    });
  }, [monitor]);

  useEffect(() => {
    if (dragg) {
      // console.log('Started asset drag');
      setDragging({ dragging: true, camera: camera });
      // Analytics.event('CANVAS/asset_add', { name: a.asset.name });
    } else {
      // console.log('stopped dragggg');
      setDragging({ dragging: false, camera: undefined });
    }
  }, [dragg]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <Tooltip
      key={camera.name + camera.fov}
      isActive={!isDragging}
      offset={{
        y: -10,
        x: -80,
      }}
      text={
        <div className="flex items-center gap-[2px]">
          <P bold className="text-lotion whitespace-nowrap">
            Drag to viewport
          </P>
          <Icon image="arrow-down-right" fill="fill-lotion" className="-mt-[2px]" />
        </div>
      }
    >
      <div ref={drag}>
        <div
          style={{ opacity }}
          className={`camera-container w-[110px] h-[110px] flex flex-col justify-center items-center 
        pointer-events-auto ${
          isDragging ? 'cursor-grabbing' : 'cursor-grab'
        } bg-[#f2f2f2] hover:bg-lotion p-[4px] rounded-[8px] shrink-0`}
        >
          <img src={camera.icon} alt={camera.name} className="mb-[10px]" />

          <p className="text-[13px] text-[#555] font-semibold">{camera.name}</p>
          <p className="text-[10px] text-[#A4A4A4] font-semibold mt-[6px]">{camera.mm}</p>
        </div>
      </div>
    </Tooltip>
  );
}
