import { GLSL3 } from 'three'; // Import GLSL3 for modern shader compatibility

// Import necessary shader chunks
import { packing } from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

/**
 * Utility function to remove unused post-processing effects from the fragment shader.
 *
 * @param {string} fragmentShader - The fragment shader code.
 * @param {string[]} effects - List of effect includes to remove.
 * @returns {string} - The modified fragment shader code.
 */
function removeUnusedEffects(fragmentShader: string, effects: string[]) {
  effects.forEach((effect) => {
    fragmentShader = fragmentShader.replace(effect, '');
  });
  return fragmentShader;
}

/**
 * Injects multiple render targets (MRT) into the fragment shader.
 *
 * @param {string} fragmentShader - The original fragment shader.
 * @returns {string} - The modified fragment shader.
 */
function injectRenderTargets(fragmentShader: string) {
  return fragmentShader.replace(
    `#include <common>`,
    `#include <common>
    layout(location = 0) out vec4 base_FragColor;
    layout(location = 1) out vec4 depth_FragColor;
    `
  );
}

/**
 * Modifies the opaque fragment shader logic to handle bloom, depth, and object rendering.
 *
 * @returns {string} - The modified opaque fragment shader code.
 */
function getOpaqueFragmentShader() {
  return `
    #ifdef OPAQUE
        diffuseColor.a = 1.0; // Ensure full opacity for opaque materials
    #endif
    
    #ifdef USE_TRANSMISSION
        diffuseColor.a *= material.transmissionAlpha; // Adjust transparency if needed
    #endif
    
    // Assign values to multiple render targets
    base_FragColor = vec4(outgoingLight, diffuseColor.a); // Store object color
    //bloom_FragColor = vec4(0.0, 0.0, 0.0, diffuseColor.a); // Disable bloom effect
    depth_FragColor = vec4(0.0, 0.0, 0.0, 0.0); // No depth information needed
  `;
}

/**
 * Updates a Three.js shader to support multiple render targets (MRT),
 * injecting custom rendering logic for transform controls while removing unnecessary effects.
 *
 * @param {Object} shader - The Three.js shader object to modify.
 * @param {Object} uniforms - Additional uniforms to be merged.
 */
export function updateTransformControlShader(shader: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;

  shader.glslVersion = GLSL3; // Enable GLSL3 for modern GPU performance

  /**
   * Ensure the 'packing' chunk is included if it's missing in the fragment shader.
   * The 'packing' chunk handles encoding/decoding of color and depth precision.
   */
  if (!shader.fragmentShader.includes(`#include <packing>`)) {
    shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, packing);
  }

  // Inject multiple render targets (MRT) for rendering different outputs
  shader.fragmentShader = injectRenderTargets(shader.fragmentShader);

  // Modify opaque fragment shader logic to handle object rendering and transparency
  shader.fragmentShader = shader.fragmentShader.replace(`#include <opaque_fragment>`, getOpaqueFragmentShader());

  /**
   * Remove unnecessary post-processing effects that are not needed for transform controls.
   * These include:
   * - Tonemapping (`tonemapping_fragment`) - Adjusts brightness/contrast for HDR.
   * - Color Space Conversion (`colorspace_fragment`) - Handles color format transformations.
   * - Fog (`fog_fragment`) - Adds atmospheric fog effects.
   * - Premultiplied Alpha (`premultiplied_alpha_fragment`) - Ensures correct blending with alpha.
   * - Dithering (`dithering_fragment`) - Reduces color banding artifacts.
   */
  const unusedEffects = [
    `#include <tonemapping_fragment>`,
    `#include <colorspace_fragment>`,
    `#include <fog_fragment>`,
    `#include <premultiplied_alpha_fragment>`,
    `#include <dithering_fragment>`,
  ];
  shader.fragmentShader = removeUnusedEffects(shader.fragmentShader, unusedEffects);
}
