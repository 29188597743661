import { GLSL3 } from 'three';

import {
  colorspace_fragment,
  dithering_fragment,
  fog_fragment,
  fragment_common,
  opaque_fragment,
  packing,
  premultiplied_alpha_fragment,
  project_vertex,
  tonemapping_fragment,
  vertex_common,
} from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

export function updateShadowPlaneShader(shader: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;

  shader.glslVersion = GLSL3;

  shader.vertexShader = shader.vertexShader.replace(`#include <common>`, vertex_common);

  shader.vertexShader = shader.vertexShader.replace(`#include <project_vertex>`, project_vertex);

  shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, fragment_common);

  shader.fragmentShader = shader.fragmentShader.replace(
    `gl_FragColor = vec4( color, opacity * ( 1.0 - getShadowMask() ) );`,

    `base_FragColor = vec4( color, opacity * ( 1.0 - getShadowMask() ) );
    depth_FragColor = vec4( 0.0 );`
  );

  shader.fragmentShader = shader.fragmentShader.replace(`#include <tonemapping_fragment>`, tonemapping_fragment);

  shader.fragmentShader = shader.fragmentShader.replace(`#include <colorspace_fragment>`, colorspace_fragment);

  shader.fragmentShader = shader.fragmentShader.replace(`#include <fog_fragment>`, fog_fragment);

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <premultiplied_alpha_fragment>`,
    premultiplied_alpha_fragment
  );

  shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, dithering_fragment);
}
