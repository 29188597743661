/*
 * StandardRenderer.ts
 *
 * This file defines the StandardRenderer class, which manages rendering and post-processing
 * effects for a Three.js scene. It handles depth and color render targets and implements
 * a basic rendering pipeline with the EffectComposer.
 *
 * The StandardRenderer creates separate render targets for depth and color information,
 * which can be used by various post-processing effects like bokeh (depth-of-field).
 */

// -----------------------------------------------------------------------------
// Import required modules, classes, and shader sources from Three.js
// -----------------------------------------------------------------------------
import {
  Scene,
  Camera,
  WebGLRenderer,
  WebGLRenderTarget,
  HalfFloatType,
  ShaderMaterial,
  UniformsUtils,
  Texture,
  Object3D,
  Object3DEventMap,
} from 'three';

// Import bokeh depth shader for depth calculation
import { BokehDepthShader } from 'three/examples/jsm/shaders/BokehShader2.js';

// Import effect composer and render pass for the post-processing pipeline
import { EffectComposer } from '../EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

// -----------------------------------------------------------------------------
// StandardRenderer Class Definition
// -----------------------------------------------------------------------------

/**
 * Class for managing standard rendering and post-processing effects on a Three.js scene.
 * It uses separate render targets for color and depth information and implements
 * a basic post-processing pipeline using EffectComposer.
 *
 * This renderer is optimized for effects that require depth information, such as
 * bokeh (depth-of-field) effects. It provides separate textures for color and depth
 * that can be consumed by other post-processing effects.
 */
export class StandardRenderer {
  // Viewport dimensions
  private width: number;
  private height: number;

  // Core Three.js objects
  private scene: Scene; // The scene containing all 3D objects
  private camera: Camera; // The camera through which the scene is viewed
  private renderer: WebGLRenderer; // The WebGLRenderer for drawing the scene

  // Render targets for storing intermediate rendering results
  private rtTextureDepth: WebGLRenderTarget; // Target for depth information
  private rtTextureColor: WebGLRenderTarget; // Target for color information

  // Material used for rendering the depth information
  private materialDepth: ShaderMaterial;

  // Effect composer for orchestrating post-processing passes
  private composer: EffectComposer;

  /**
   * Constructor for the StandardRenderer class.
   * Sets up render targets, depth material, and the effect composer with a basic render pass.
   *
   * @param width - Initial width of render targets. Defaults to window width.
   * @param height - Initial height of render targets. Defaults to window height.
   * @param scene - The main Three.js scene containing all 3D objects.
   * @param camera - The camera through which the scene is viewed.
   * @param renderer - The WebGLRenderer instance used for rendering.
   */
  constructor(
    width: number = window.innerWidth,
    height: number = window.innerHeight,
    scene: Scene,
    camera: Camera,
    renderer: WebGLRenderer
  ) {
    // Initialize basic properties
    this.width = width;
    this.height = height;
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;

    // Calculate optimized sizes for the depth render target
    // Using power-of-two dimensions improves performance and compatibility
    const depthScale = 0.5; // Use half resolution for depth to improve performance
    const depthWidth = Math.pow(2, Math.floor(Math.log2(width * depthScale)));
    const depthHeight = Math.pow(2, Math.floor(Math.log2(height * depthScale)));

    // Create render targets for depth and color
    // Using HalfFloatType for better precision while maintaining performance
    this.rtTextureDepth = new WebGLRenderTarget(depthWidth, depthHeight, {
      type: HalfFloatType,
      generateMipmaps: false, // Disable mipmaps to improve performance
    });

    this.rtTextureColor = new WebGLRenderTarget(this.width, this.height, {
      type: HalfFloatType,
      generateMipmaps: false, // Disable mipmaps to improve performance
    });

    // Set up depth material using BokehDepthShader for calculating scene depth
    this.materialDepth = new ShaderMaterial({
      uniforms: UniformsUtils.clone(BokehDepthShader.uniforms),
      vertexShader: BokehDepthShader.vertexShader,
      fragmentShader: BokehDepthShader.fragmentShader,
    });

    // Configure depth material uniforms for near and far planes
    this.materialDepth.uniforms['mNear'].value = 1.0; // Near clipping plane
    this.materialDepth.uniforms['mFar'].value = 30.0; // Far clipping plane

    // Set up the effect composer with a basic render pass
    this.composer = new EffectComposer(this.renderer);
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass); // Add the render pass as the first pass
  }

  // -----------------------------------------------------------------------------
  // Public Methods
  // -----------------------------------------------------------------------------

  /**
   * Resizes the render targets when the viewport dimensions change.
   * This method should be called whenever the window or container is resized.
   *
   * @param width - The new width of the viewport.
   * @param height - The new height of the viewport.
   * @param pixelRatio - The device's pixel ratio for high-DPI displays.
   */
  public resize(width: number, height: number, pixelRatio: number): void {
    // Only resize if dimensions have actually changed to avoid unnecessary operations
    if (this.width === width && this.height === height) return;

    // Update internal dimensions
    this.width = width;
    this.height = height;

    // Recalculate optimized sizes for the depth render target
    const depthScale = 0.5;
    const depthWidth = Math.pow(2, Math.floor(Math.log2(width * depthScale)));
    const depthHeight = Math.pow(2, Math.floor(Math.log2(height * depthScale)));

    // Resize the render targets to match the new dimensions
    this.rtTextureDepth.setSize(depthWidth, depthHeight);
    this.rtTextureColor.setSize(width, height);
  }

  /**
   * Renders the scene to the color and depth targets and then composites the result.
   * This is the main rendering method that should be called on each animation frame.
   */
  public render(): void {
    if (!this.renderer || !this.scene || !this.camera || !this.materialDepth) {
      console.error('Cannot render: renderer, scene, camera, or materialDepth is null');
      return;
    }

    // Clear the renderer to start fresh
    this.renderer.clear();

    // First, render to the depth target using the depth material
    const originalOverride = this.scene.overrideMaterial; // Save original material
    this.scene.overrideMaterial = this.materialDepth; // Override with depth material
    this.renderer.setRenderTarget(this.rtTextureDepth); // Set depth target
    this.renderer.clear(); // Clear depth target
    this.renderer.render(this.scene, this.camera); // Render scene to depth target
    this.scene.overrideMaterial = originalOverride; // Restore original material

    // Finally, render the composed image to the screen
    // The effect composer will handle the post-processing pipeline
    this.renderer.setRenderTarget(null); // Set render target to the screen
    
    if (this.composer) {
      this.composer.render(); // Render with all configured passes
    }
  }

  /**
   * Returns the EffectComposer instance.
   * This allows external code to access and manipulate the post-processing pipeline.
   *
   * @returns The EffectComposer managing the post-processing passes.
   */
  public getComposer(): EffectComposer {
    if (!this.composer) {
      throw new Error('EffectComposer is not initialized');
    }
    return this.composer;
  }

  /**
   * Returns the color texture from the color render target.
   * This can be used by external post-processing effects.
   *
   * @returns The color texture.
   */
  public getTextureColor(): Texture {
    if (!this.rtTextureColor) {
      throw new Error('Color texture is not initialized');
    }
    return this.rtTextureColor.texture;
  }

  /**
   * Returns the depth texture from the depth render target.
   * This can be used by external post-processing effects that require depth information.
   *
   * @returns The depth texture.
   */
  public getTextureDepth(): Texture {
    if (!this.rtTextureDepth) {
      throw new Error('Depth texture is not initialized');
    }
    return this.rtTextureDepth.texture;
  }

  /**
   * Disposes of all resources used by this renderer.
   * Call this method when the renderer is no longer needed to prevent memory leaks.
   * This is important for proper garbage collection and memory management.
   */
  public dispose(): void {
    // Dispose render targets
    if (this.rtTextureDepth) {
      this.rtTextureDepth.dispose();
      this.rtTextureDepth = null as unknown as WebGLRenderTarget;
    }

    if (this.rtTextureColor) {
      this.rtTextureColor.dispose();
      this.rtTextureColor = null as unknown as WebGLRenderTarget;
    }

    // Dispose materials
    if (this.materialDepth) {
      this.materialDepth.dispose();
      this.materialDepth = null as unknown as ShaderMaterial;
    }

    // Dispose composer and its passes
    if (this.composer) {
      // Dispose each pass in the composer
      const passes = this.composer.passes;
      for (let i = 0; i < passes.length; i++) {
        const pass = passes[i];
        if (pass.dispose) {
          pass.dispose();
        }
      }

      this.composer.dispose();
      this.composer = null as unknown as EffectComposer;
    }

    // Remove references to external objects
    // This helps the garbage collector reclaim memory
    this.scene = null as unknown as Scene;
    this.camera = null as unknown as Camera;
    this.renderer = null as unknown as WebGLRenderer;
  }
}