import React from 'react';
import DropdownList from './DropdownList';
import IListItem from './types';
interface IDropdownButtonProps {
  title: string;
  list: IListItem[];
  onClick: (url: string) => void;
  color?: string;
  background?: string;
  iconRight?: boolean;
  //! both background and color should be passed as a string of hex (#123432) values
}

export default function DropdownButton({ title, list, onClick, background, color, iconRight }: IDropdownButtonProps) {
  return (
    <div
      className={`flex flex-row relative group items-center justify-items-end justify-between cursor-pointer ${
        background ? `bg-[${background}]` : ' bg-han-blue-200 '
      } ${color && `text-[${color}]`} rounded-[4px] py-[10px] px-[12px]
      
              `}
    >
      <div></div>
      <p className={`text-[11px] font-demi text-lotion mt-[2px]`}>{title.toUpperCase()}</p>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          className={`w-[10px] h-[10px] ml-1 transform transition ease-in-out duration-200 rotate-180 group-hover:rotate-0`}
        >
          <path
            d="M0.666501 4.97847C0.444499 4.74337 0.444499 4.365 0.666501 4.1299L3.59936 1.02398C3.70515 0.910105 3.84911 0.847656 4 0.847656C4.15089 0.847656 4.29485 0.910105 4.40064 1.02398L7.3335 4.1299C7.5555 4.365 7.5555 4.74337 7.3335 4.97847C7.1115 5.21357 6.75421 5.21357 6.53221 4.97847L4.1446 2.47979C4.06582 2.39736 3.93418 2.39736 3.8554 2.47979L1.46779 4.97847C1.35679 5.09602 1.21283 5.1548 1.06715 5.1548C0.921456 5.1548 0.777502 5.09602 0.666501 4.97847Z"
            fill={`#fcfcfc`}
          />
        </svg>
      </div>
      {iconRight ? (
        <DropdownList list={list} onClick={onClick} iconRight />
      ) : (
        <DropdownList list={list} onClick={onClick} />
      )}
    </div>
  );
}
