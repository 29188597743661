import { GLSL3 } from 'three'; // Import GLSL3 for modern shader compatibility

// Import shader chunks
import {
  packing, // Handles data packing and precision adjustments
  vertex_common, // Common vertex shader utilities
} from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

/**
 * Utility function to replace shader includes with custom implementations.
 *
 * @param {string} shaderCode - The shader source code.
 * @param {Object} replacements - An object mapping include keys to their replacements.
 * @returns {string} - The updated shader source code.
 */
function replaceShaderChunks(shaderCode: any, replacements: any) {
  Object.entries(replacements).forEach(([key, value]) => {
    shaderCode = shaderCode.replace(`#include <${key}>`, value);
  });
  return shaderCode;
}

/**
 * Generates modified vertex shader code with additional varying variables.
 * - Stores local vertex position and transformed world position.
 *
 * @param {string} vertexShader - The original vertex shader code.
 * @returns {string} - The updated vertex shader.
 */
function modifyVertexShader(vertexShader: string) {
  return (
    `
    precision mediump float;
    varying float vViewZDepth;
    varying vec3 vertexPosition;
    varying vec3 worldPositionz;
    ` +
    vertexShader.replace(
      `#include <project_vertex>`,
      `
      vertexPosition = position.xyz;
      vec4 mvPosition = vec4( vertexPosition.xyz, 1.0 );
      mvPosition = modelViewMatrix * mvPosition;
  
      gl_Position = projectionMatrix * mvPosition;
      worldPositionz = mvPosition.xyz;
  
      vViewZDepth = -mvPosition.z;
      `
    )
  );
}

/**
 * Generates modified fragment shader code with additional uniforms and varying variables.
 *
 * @param {string} fragmentShader - The original fragment shader code.
 * @returns {string} - The updated fragment shader.
 */
function modifyFragmentShader(fragmentShader: string) {
  return (
    `
    precision mediump float;
    ` +
    fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      varying vec3 vertexPosition;
      varying vec3 worldPositionz;
      uniform float uDistance; // Distance threshold for fade effect
      uniform vec3 uColor1; // Primary grid color
      uniform vec3 uColor2; // Secondary grid color
      varying float vViewZDepth; // View-space depth for depth-based effects
      layout(location = 0) out vec4 base_FragColor; // Main color output
      layout(location = 1) out vec4 depth_FragColor; // Depth effect output
      `
    )
  );
}

/**
 * Generates the modified opaque fragment shader code to support transparency, bloom, and grid effects.
 *
 * @returns {string} - The modified opaque fragment shader code.
 */
function getOpaqueFragmentShader() {
  return `
    #ifdef OPAQUE
      diffuseColor.a = 1.0;
    #endif

    #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
    #endif

    // Compute distance-based fade effect
    float fadeFactor = smoothstep(uDistance, 0.0, -worldPositionz.z);

    base_FragColor = vec4(outgoingLight.rgb, diffuseColor.a);

    //

    float threshold = 0.0001;

    // Compute X-axis grid line
    float xLine1 = step(-threshold, vertexPosition.x);
    float xLine2 = step(vertexPosition.x, threshold);
    float xMask = xLine1 * xLine2;

    // Compute Z-axis grid line
    float zLine1 = step(-threshold, vertexPosition.z);
    float zLine2 = step(vertexPosition.z, threshold);
    float zMask = zLine1 * zLine2;

    // Combine edges to form the grid pattern
    float gridMask = xMask + zMask;

    //

    // Blend grid colors
    base_FragColor.rgb = mix(uColor2, uColor1, gridMask);

    // Apply fade effect
    base_FragColor.a *= fadeFactor * fadeFactor;
    
    // Disable bloom effect for grid elements
    //bloom_FragColor = vec4(0.0);

    //

    // Compute depth effect
    float cameraNear = 1.0; // Hardcoded near plane distance
    float cameraFar = 30.0; // Hardcoded far plane distance
    float depthIntensity = 1.0 - smoothstep(cameraNear, cameraFar, vViewZDepth);
    depth_FragColor = vec4(vec3(depthIntensity), 1.0);

    // Discard fragments with very low alpha to optimize rendering
    if (base_FragColor.a <= 0.001) discard;
  `;
}

/**
 * Updates a Three.js shader by setting GLSL3, injecting uniforms,
 * modifying vertex and fragment shaders, and applying custom grid rendering effects.
 *
 * @param {Object} shader - The Three.js shader object to modify.
 * @param {Object} uniforms - Additional uniforms to be merged.
 */
export function updateGridShader(shader: any, uniforms: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;
  shader.glslVersion = GLSL3; // Enable modern GPU features

  // Merge provided uniforms with existing shader uniforms
  shader.uniforms = { ...shader.uniforms, ...uniforms };

  // Ensure 'packing' is included if missing
  if (!shader.fragmentShader.includes(`#include <packing>`)) {
    shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, packing);
  }

  // Apply shader modifications
  shader.vertexShader = modifyVertexShader(shader.vertexShader);
  shader.fragmentShader = modifyFragmentShader(shader.fragmentShader);

  // Replace opaque fragment shader with custom logic
  shader.fragmentShader = shader.fragmentShader.replace(`#include <opaque_fragment>`, getOpaqueFragmentShader());

  // Remove post-processing effects that are not needed for this shader
  const unusedEffects = [
    `#include <tonemapping_fragment>`,
    `#include <colorspace_fragment>`,
    `#include <fog_fragment>`,
    `#include <premultiplied_alpha_fragment>`,
    `#include <dithering_fragment>`,
  ];

  unusedEffects.forEach((effect) => {
    shader.fragmentShader = shader.fragmentShader.replace(effect, ``);
  });
}
