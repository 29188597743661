/*
 * MultipleRenderTarget.ts
 *
 * This file defines the MultipleRenderTarget class, which implements a multiple render target (MRT)
 * system for Three.js. It enables rendering a scene into multiple textures simultaneously
 * (such as color and depth) for use in advanced post-processing effects like depth-of-field.
 *
 * The class manages the creation of WebGLRenderTargets with multiple attachments, sets up the
 * necessary render passes, and provides methods to access individual texture outputs.
 */

// -----------------------------------------------------------------------------
// Import required modules, classes, and shader sources from Three.js
// -----------------------------------------------------------------------------
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  LinearFilter,
  RGBAFormat,
  ShaderMaterial,
  GLSL3,
  WebGLRenderTarget,
  Texture,
} from 'three';

// Import composers and passes for the post-processing pipeline
import { EffectComposer } from '../EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { MRTPass } from '../passes/MRTPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

// Import the custom shader sources for the mix pass that combines texture outputs
import { mixPassVertexShader, mixPassFragmentShader } from '../shaders/mixPass/mixPass';

// -----------------------------------------------------------------------------
// MultipleRenderTarget Class Definition
// -----------------------------------------------------------------------------

/**
 * MultipleRenderTarget class manages rendering a scene into multiple outputs
 * (such as color and depth textures) using Multiple Render Targets (MRT)
 * and then composites these outputs using a custom mix shader.
 *
 * This is useful for post-processing effects where different render outputs
 * are needed (for example, to later apply depth-of-field or selective bloom effects).
 *
 * Note: Bloom effect related code is present but currently disabled.
 */
export class MultipleRenderTarget {
  // -----------------------------------------------------------------------------
  // Core Rendering Components
  // -----------------------------------------------------------------------------

  /** The main Three.js scene to be rendered */
  private scene: Scene;

  /** The primary perspective camera used for scene rendering */
  private camera: PerspectiveCamera;

  /** Main composer for combining render passes into the final output */
  private composer: EffectComposer;

  // -----------------------------------------------------------------------------
  // Multiple Render Targets and MRT Pass
  // -----------------------------------------------------------------------------

  /**
   * Render target that holds multiple textures (MRT):
   * - Texture 0: Base color render (without bloom)
   * - Texture 1: Depth information (for effects like Depth of Field)
   */
  public multipleRenderTargets: WebGLRenderTarget;

  /** Custom pass that renders the scene into the multiple render targets */
  public mrtPass: MRTPass;

  // -----------------------------------------------------------------------------
  // Mix Pass Setup (for compositing textures)
  // -----------------------------------------------------------------------------

  /** ShaderPass that combines the different render targets into a final image */
  private mixPass: ShaderPass;

  /** Uniforms for the mix shader (provides access to the render textures) */
  private mixPassUniforms: any;

  /**
   * Constructor for the MultipleRenderTarget class.
   *
   * @param width - The width of the render target (in pixels)
   * @param height - The height of the render target (in pixels)
   * @param scene - The main Three.js scene to render
   * @param camera - The perspective camera used to view the scene
   * @param renderer - The WebGLRenderer instance used for rendering
   */
  constructor(width: number, height: number, scene: Scene, camera: PerspectiveCamera, renderer: WebGLRenderer) {
    // Capture device pixel ratio to support high-DPI (retina) displays
    const pixelRatio = renderer.getPixelRatio();

    // Store the primary scene and camera for later use
    this.scene = scene;
    this.camera = camera;

    // -----------------------------------------------------------------------------
    // Initialize Multiple Render Targets
    // -----------------------------------------------------------------------------

    // Create a WebGLRenderTarget configured for multiple render targets
    // The target holds two textures:
    //   [0] Base render: Standard scene color
    //   [1] Depth: Grayscale depth information for additional effects
    this.multipleRenderTargets = new WebGLRenderTarget(width * pixelRatio, height * pixelRatio, {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
      count: 2, // Allocate 2 textures
      generateMipmaps: false, // Disable mipmaps for performance
      samples: 4, // Multi-sampling for antialiasing
    });

    // Create an MRTPass to render the scene into our multiple render targets
    this.mrtPass = new MRTPass(this.scene, this.camera, this.multipleRenderTargets);

    // -----------------------------------------------------------------------------
    // Initialize Mix Pass for Compositing Render Targets
    // -----------------------------------------------------------------------------

    // Define uniforms for the mix shader:
    // - baseTexture: The main color render from the MRT
    this.mixPassUniforms = {
      baseTexture: { value: this.multipleRenderTargets.textures[0] },
      // Note: Additional textures like bloomTexture could be added here
    };

    // Create a ShaderPass for mixing the render targets
    // This approach is more efficient than creating a separate scene
    this.mixPass = new ShaderPass(
      new ShaderMaterial({
        uniforms: this.mixPassUniforms,
        vertexShader: mixPassVertexShader,
        fragmentShader: mixPassFragmentShader,
        glslVersion: GLSL3,
      })
    );

    // -----------------------------------------------------------------------------
    // Initialize Effect Composer and Add Passes
    // -----------------------------------------------------------------------------

    // Initialize the main EffectComposer for final post-processing
    this.composer = new EffectComposer(renderer);

    // Add the MRT pass to render the scene into the multiple render targets
    this.composer.addPass(this.mrtPass);

    // Add the mix pass to composite the final image
    this.composer.addPass(this.mixPass);
  }

  // -----------------------------------------------------------------------------
  // Public Methods
  // -----------------------------------------------------------------------------

  /**
   * Update the size of the render targets and composers when the viewport changes.
   *
   * @param width - The new width of the viewport (in pixels)
   * @param height - The new height of the viewport (in pixels)
   * @param pixelRatio - The device pixel ratio to support high-DPI displays
   */
  public setSize(width: number, height: number, pixelRatio: number) {
    // Adjust the size of the multiple render targets
    this.multipleRenderTargets.setSize(width * pixelRatio, height * pixelRatio);

    // Adjust the size of the EffectComposer to ensure the final output matches the new dimensions
    this.composer.setSize(width * pixelRatio, height * pixelRatio);
  }

  /**
   * Render the final composited image. This method should be called once per frame.
   */
  public render() {
    // Update uniforms to ensure textures are correctly referenced
    const mixMaterial = this.mixPass.material as ShaderMaterial;
    mixMaterial.uniforms.baseTexture.value = this.multipleRenderTargets.textures[0];

    // Render the entire post-processing pipeline
    this.composer.render();
  }

  /**
   * Get the main EffectComposer for further external manipulation.
   *
   * @returns {EffectComposer} The main post-processing composer
   */
  public getComposer(): EffectComposer {
    return this.composer;
  }

  /**
   * Retrieve the base color render texture from the multiple render targets.
   *
   * @returns {Texture} The color texture (base scene render)
   */
  public getColorScene(): Texture {
    return this.multipleRenderTargets.textures[0];
  }

  /**
   * Retrieve the depth texture from the multiple render targets.
   * This texture can be used for depth-based post-processing effects, such as Depth of Field (DoF).
   *
   * @returns {Texture} The depth texture
   */
  public getDepthScene(): Texture {
    return this.multipleRenderTargets.textures[1];
  }
}
