/**
 * Code that handles packing of data.
 */
export const packing = 
`#include <packing>
#include <common>
`;

/**
 * Code that runs in the vertex shader, and common to all shaders.
 */
export const vertex_common =
`varying float vViewZDepth;
#include <common>`;

/**
 * Code that projects the vertex position into screen space.
 */
export const project_vertex =
`#include <project_vertex>
vViewZDepth = - mvPosition.z;
`;

/**
 * Common code that runs in the fragment shader, and common to all shaders.
 */
export const fragment_common = 
`#include <common>
uniform float bloomLayer;
uniform float isHovered;
varying float vViewZDepth;
layout(location = 0) out vec4 base_FragColor;
layout(location = 1) out vec4 depth_FragColor;
`;

/**
 * Code that runs in the fragment shader, and is specific to opaque objects.
 */
export const opaque_fragment =
`#ifdef OPAQUE
    diffuseColor.a = 1.0;
#endif

#ifdef USE_TRANSMISSION
    diffuseColor.a *= material.transmissionAlpha;
#endif

vec4 objectColor = vec4( outgoingLight, diffuseColor.a );

vec4 hoveringColor = vec4(1.0);

base_FragColor = mix(objectColor, hoveringColor, isHovered);
//bloom_FragColor = vec4( 0.0 );//outgoingLight * bloomLayer, diffuseColor.a );

float cameraNear = 1.0; //hard coded for now
float cameraFar = 30.0;
float color = 1.0 - smoothstep( cameraNear, cameraFar, vViewZDepth );
depth_FragColor = vec4( vec3( color ), 1.0 );
`;

/**
 * Code that runs in the fragment shader, and is specific to tone mapping.
 */
export const tonemapping_fragment =
`#if defined( TONE_MAPPING )
    //bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
    base_FragColor.rgb = toneMapping( base_FragColor.rgb );
#endif`;

/**
 * Code that runs in the fragment shader, and is specific to color space conversion.
 */
export const colorspace_fragment =
`//bloom_FragColor = linearToOutputTexel( bloom_FragColor );
base_FragColor = linearToOutputTexel( base_FragColor );
`;

/**
 * Code that runs in the fragment shader, and is specific to fogging.
 */
export const fog_fragment =
`#ifdef USE_FOG
    #ifdef FOG_EXP2
        float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
    #else
        float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
    #endif
    //bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
    base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
#endif`;

/**
 * Code that runs in the fragment shader, and is specific to premultiplied alpha blending.
 */
export const premultiplied_alpha_fragment =
`#ifdef PREMULTIPLIED_ALPHA
    // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
    //bloom_FragColor.rgb *= bloom_FragColor.a;
    base_FragColor.rgb *= base_FragColor.a;
#endif`;

/**
 * Code that runs in the fragment shader, and is specific to dithering.
 */
export const dithering_fragment =
`#ifdef DITHERING
    //bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
    base_FragColor.rgb = dithering( base_FragColor.rgb );
#endif`;