import { GLSL3 } from 'three'; // Import GLSL3 for modern shader compatibility

// Import custom shader chunks to replace default Three.js shader includes
import {
  colorspace_fragment, // Handles color space transformations
  dithering_fragment, // Reduces color banding artifacts
  fog_fragment, // Implements fog rendering
  premultiplied_alpha_fragment, // Ensures correct blending with premultiplied alpha
  tonemapping_fragment, // Applies tone mapping for brightness and contrast adjustments
} from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

/**
 * Replaces specified shader includes with corresponding custom implementations.
 *
 * @param {string} shaderCode - The shader source code.
 * @param {Object} replacements - An object mapping include keys to their replacements.
 * @returns {string} - The updated shader source code.
 */
function replaceShaderChunks(shaderCode: any, replacements: any) {
  Object.entries(replacements).forEach(([key, value]) => {
    shaderCode = shaderCode.replace(`#include <${key}>`, value);
  });
  return shaderCode;
}

/**
 * Updates a Three.js shader to support Multiple Render Targets (MRT)
 * and injects various rendering enhancements.
 *
 * @param {Object} shader - The Three.js shader object.
 * @param {Object} uniforms - Additional uniforms to be merged.
 */
export function updateSkyShader(shader: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;

  shader.glslVersion = GLSL3;

  /**
   * Introduce Multiple Render Targets (MRT) to the fragment shader.
   * These allow storing different rendering data in separate output buffers:
   * - `base_FragColor`: Stores the standard color output.
   * - `bloom_FragColor`: Stores bloom intensity (black by default).
   * - `depth_FragColor`: Stores depth information.
   */
  shader.fragmentShader = shader.fragmentShader.replace(
    `varying float vSunE;`,
    `varying float vSunE;
    layout(location = 0) out vec4 base_FragColor;
    layout(location = 1) out vec4 depth_FragColor;
    `
  );

  /**
   * Modify the final fragment output to store data in multiple render targets.
   * This replaces `gl_FragColor` with individual buffers for color, bloom, and depth.
   */
  shader.fragmentShader = shader.fragmentShader.replace(
    `gl_FragColor = vec4( retColor, 1.0 );`,
    `base_FragColor = vec4( retColor, 1.0 );
    //bloom_FragColor = vec4( 0.0, 0.0, 0.0, 1.0 );
    depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );`
  );

  // Define shader chunk replacements
  const fragmentShaderReplacements = {
    tonemapping_fragment,
    colorspace_fragment,
    fog_fragment,
    premultiplied_alpha_fragment,
    dithering_fragment,
  };

  // Apply chunk replacements to the fragment shader
  shader.fragmentShader = replaceShaderChunks(shader.fragmentShader, fragmentShaderReplacements);
}
