import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Icon, P } from '@radical/ui';
import IListItem from './types';

type Props = {
  onClick: (url: string) => void;
  list: IListItem[];
  iconRight?: boolean;
};
export default function DropdownList({ onClick, list, iconRight }: Props) {
  return (
    <div
      className={`bg-[#FCFCFC] absolute top-[40px] left-0 w-full rounded-[8px] py-[16px] px-[12px] gap-[12px] hidden group-hover:flex 
                flex-1 flex-col items-center justify-center h-auto z-10 shadow-lg`}
    >
      {list.map((item, i) => {
        return (
          <div
            key={item.title}
            onClick={() => onClick(item.url)}
            className="flex w-full h-[48px] px-[8px] items-center justify-between bg-[#f2f2f2] hover:bg-[#f7f7f7] hover:cursor-pointer"
          >
            <div className="flex gap-[16px] items-center justify-between">
              <Icon image={item.icon} size="md" />
              <P>{item.title}</P>
            </div>
            {iconRight && (
              <div
                className="flex items-center justify-center h-auto w-auto cursor-pointer"
                onClick={() => window.open(item.url, '_blank')}
              >
                <Icon image="open-link" size="md" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
