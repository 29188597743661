import { GLSL3 } from 'three'; // Import GLSL3 for modern shader compatibility

// Import shader chunks to replace default Three.js shader includes
import {
  colorspace_fragment, // Handles color space transformations
  dithering_fragment, // Applies dithering to reduce color banding
  fog_fragment, // Implements fog rendering
  packing, // Manages data packing for storage and precision
  premultiplied_alpha_fragment, // Ensures correct blending with premultiplied alpha
  tonemapping_fragment, // Applies tone mapping for brightness and contrast adjustments
} from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

/**
 * Updates a Three.js shader by setting GLSL3, injecting custom uniforms,
 * modifying fragment shader behavior, and replacing shader chunks with custom implementations.
 *
 * @param {Object} shader - The Three.js shader object to modify.
 * @param {Object} uniforms - Additional uniforms to be merged into the shader.
 */
export function updateBackgroundShader(shader: any, uniforms: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;

  // Set GLSL version to GLSL3 to ensure modern shader features and better performance
  shader.glslVersion = GLSL3;

  // Merge provided uniforms with the existing shader uniforms
  shader.uniforms = { ...shader.uniforms, ...uniforms };

  /**
   * Ensure the 'packing' chunk is included if it's missing in the fragment shader.
   * This is used for encoding/decoding depth and color precision.
   */
  if (!shader.fragmentShader.includes(`#include <packing>`)) {
    shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, packing);
  }

  /**
   * Inject custom uniforms into the fragment shader.
   * - `backgroundColor`: Background color for blending.
   * - `isEnvironment`: Boolean flag to determine if an object belongs to the environment.
   * - `focusLength`: Depth value for focus-based rendering.
   * - Multiple render targets for different outputs:
   *   - `base_FragColor`: Stores the base color output.
   *   - `bloom_FragColor`: Stores bloom intensity.
   *   - `depth_FragColor`: Stores depth information.
   */
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <common>`,
    `#include <common>
    uniform vec3 backgroundColor;
    uniform bool isEnvironment;
    uniform float focusLength;
    layout(location = 0) out vec4 base_FragColor;
    layout(location = 1) out vec4 depth_FragColor;
    `
  );

  /**
   * Modify the fragment shader to:
   * - Ensure full opacity for opaque materials.
   * - Adjust transparency if `USE_TRANSMISSION` is enabled.
   * - Implement a bloom effect with black as default.
   * - Blend object color with background color based on `isEnvironment` uniform.
   * - Compute depth-based rendering using `focusLength`.
   */
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <opaque_fragment>`,
    `#ifdef OPAQUE
    diffuseColor.a = 1.0; // Ensure full opacity for opaque materials
    #endif
    
    #ifdef USE_TRANSMISSION
    diffuseColor.a *= material.transmissionAlpha; // Adjust transparency
    #endif
    
    // Initialize bloom color (black by default)
    //bloom_FragColor = vec4(0.0, 0.0, 0.0, diffuseColor.a);

    // Blend the background color with the object color based on isEnvironment
    base_FragColor = vec4(mix(backgroundColor, outgoingLight, float(isEnvironment)), diffuseColor.a);

    // Compute depth value using a smoothstep function for depth-based rendering
    float depthColor = smoothstep(30.0, 27.5, focusLength);
    depth_FragColor = vec4(depthColor, 0.0, 0.0, diffuseColor.a);
    `
  );

  // Replace default tone mapping logic with a custom implementation
  shader.fragmentShader = shader.fragmentShader.replace(`#include <tonemapping_fragment>`, tonemapping_fragment);

  // Replace color space transformations with a custom implementation
  shader.fragmentShader = shader.fragmentShader.replace(`#include <colorspace_fragment>`, colorspace_fragment);

  // Replace default fog rendering with a custom fog effect
  shader.fragmentShader = shader.fragmentShader.replace(`#include <fog_fragment>`, fog_fragment);

  // Replace premultiplied alpha blending with a custom implementation
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <premultiplied_alpha_fragment>`,
    premultiplied_alpha_fragment
  );

  // Replace dithering logic with a custom process to reduce color banding
  shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, dithering_fragment);
}
