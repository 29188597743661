import { GLSL3 } from 'three'; // Import GLSL3 for modern shader compatibility

// Import shader chunks used for various rendering effects
import {
  colorspace_fragment, // Handles color space transformations
  dithering_fragment, // Applies dithering to reduce color banding
  fog_fragment, // Implements fog rendering
  fragment_common, // Common fragment shader utilities
  packing, // Handles data packing and precision
  premultiplied_alpha_fragment, // Ensures correct blending with premultiplied alpha
  project_vertex, // Transforms vertices into clip space
  tonemapping_fragment, // Applies tone mapping for brightness and contrast adjustments
  vertex_common, // Common vertex shader utilities
} from './chunks';
import { useSceneInformationStore } from '@radical/canvas-store';

/**
 * Utility function to replace shader includes with custom implementations.
 *
 * @param {string} shaderCode - The shader source code.
 * @param {Object} replacements - An object mapping include keys to their replacements.
 * @returns {string} - The updated shader source code.
 */
function replaceShaderChunks(shaderCode: any, replacements: any) {
  Object.entries(replacements).forEach(([key, value]) => {
    shaderCode = shaderCode.replace(`#include <${key}>`, value);
  });
  return shaderCode;
}

/**
 * Generates the modified opaque fragment shader code to support transparency, bloom, and hover effects.
 *
 * @param {boolean} isHoverable - Determines if the object should react to hover effects.
 * @returns {string} - The modified opaque fragment shader code.
 */
function getOpaqueFragmentShader(isHoverable: boolean) {
  return `
    #ifdef OPAQUE
      diffuseColor.a = 1.0; // Force full opacity for opaque materials
    #endif

    #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha; // Adjust transparency
    #endif

    // Compute final object color
    vec4 objectColor = vec4(outgoingLight, diffuseColor.a);

    // Define hover highlight color (pure white)
    vec4 hoveringColor = vec4(1.0);

    // Blend object color with hover effect based on hover state
    base_FragColor = mix(objectColor, hoveringColor, isHovered * float(${isHoverable}));

    // Bloom layer: emphasize light-emitting areas
    //bloom_FragColor = vec4(outgoingLight * bloomLayer, diffuseColor.a);

    // Compute depth for depth-based effects (e.g., fog, DOF)
    float cameraNear = 1.0; // Hardcoded near clipping plane
    float cameraFar = 30.0; // Hardcoded far clipping plane
    float depthValue = 1.0 - smoothstep(cameraNear, cameraFar, vViewZDepth);
    depth_FragColor = vec4(vec3(depthValue), 1.0);
  `;
}

/**
 * Updates a Three.js shader by setting GLSL3, injecting uniforms,
 * supporting hover effects, and replacing shader chunks with custom implementations.
 *
 * @param {boolean} isHoverable - Determines if the object should react to hover effects.
 * @param {Object} shader - The Three.js shader object to modify.
 * @param {Object} uniforms - Additional uniforms to be merged.
 */
export function updateCameraShader(isHoverable: boolean, shader: any, uniforms: any) {
  if (useSceneInformationStore.getState().useStandardRenderer) return;

  shader.glslVersion = GLSL3; // Enable GLSL3 for modern GPU compatibility

  // Merge provided uniforms with existing shader uniforms
  shader.uniforms = { ...shader.uniforms, ...uniforms };

  // Ensure 'packing' is included if missing
  if (!shader.fragmentShader.includes(`#include <packing>`)) {
    shader.fragmentShader = shader.fragmentShader.replace(`#include <common>`, packing);
  }

  // Define shader chunk replacements
  const shaderReplacements = {
    common: fragment_common,
    tonemapping_fragment,
    colorspace_fragment,
    fog_fragment,
    premultiplied_alpha_fragment,
    dithering_fragment,
  };

  // Apply shader chunk replacements
  shader.vertexShader = replaceShaderChunks(shader.vertexShader, {
    common: vertex_common,
    project_vertex,
  });

  shader.fragmentShader = replaceShaderChunks(shader.fragmentShader, shaderReplacements);

  // Inject hover logic into the opaque fragment shader
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <opaque_fragment>`,
    getOpaqueFragmentShader(isHoverable)
  );
}
