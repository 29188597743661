/**
 * Combined Post-Processing Shader
 * Merges ACESFilmicToneMapping, GammaCorrection, and Crop shaders into one pass
 * with individual toggle controls for each effect
 */

const CombinedPostProcessingShader = {
    name: 'CombinedPostProcessingShader',
  
    uniforms: {
      'tDiffuse': { value: null },
      'exposure': { value: 1.0 },
      'uCanvasAspectRatio': { value: 1.0 },
      'uTargetAspectRatio': { value: 1.0 },
      'uBlackBarOpacity': { value: 0.3 },
      // Toggle controls for each effect
      'enableToneMapping': { value: true },
      'enableGammaCorrection': { value: true },
      'enableCrop': { value: true }
    },
  
    vertexShader: /* glsl */`
      varying vec2 vUv;
  
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`,
  
    fragmentShader: /* glsl */`
      // Using custom clamp function instead of saturate macro
      // to avoid conflicts with Three.js built-in definitions
      #ifndef COMBINED_CUSTOM_SATURATE
      #define COMBINED_CUSTOM_SATURATE
      vec3 combinedSaturate(vec3 a) { return clamp(a, 0.0, 1.0); }
      float combinedSaturate(float a) { return clamp(a, 0.0, 1.0); }
      vec4 combinedSaturate(vec4 a) { return clamp(a, 0.0, 1.0); }
      #endif
  
      uniform sampler2D tDiffuse;
      uniform float exposure;
      uniform float uCanvasAspectRatio;
      uniform float uTargetAspectRatio;
      uniform float uBlackBarOpacity;
      
      // Toggle controls for each effect
      uniform bool enableToneMapping;
      uniform bool enableGammaCorrection;
      uniform bool enableCrop;
  
      varying vec2 vUv;
  
      // Using unique function names to avoid conflicts with any Three.js built-ins
      vec3 combinedRRTAndODTFit(vec3 v) {
        vec3 a = v * (v + 0.0245786) - 0.000090537;
        vec3 b = v * (0.983729 * v + 0.4329510) + 0.238081;
        return a / b;
      }
  
      vec3 combinedACESFilmicToneMapping(vec3 color) {
        // sRGB => XYZ => D65_2_D60 => AP1 => RRT_SAT
        const mat3 ACESInputMat = mat3(
          vec3(0.59719, 0.07600, 0.02840),
          vec3(0.35458, 0.90834, 0.13383),
          vec3(0.04823, 0.01566, 0.83777)
        );
  
        // ODT_SAT => XYZ => D60_2_D65 => sRGB
        const mat3 ACESOutputMat = mat3(
          vec3(1.60475, -0.10208, -0.00327),
          vec3(-0.53108, 1.10813, -0.07276),
          vec3(-0.07367, -0.00605, 1.07602)
        );
  
        color = ACESInputMat * color;
        // Apply RRT and ODT
        color = combinedRRTAndODTFit(color);
        color = ACESOutputMat * color;
  
        // Clamp to [0, 1]
        return combinedSaturate(color);
      }
  
      // Unique function name for gamma correction
      vec4 combinedSRGBTransferOETF(vec4 color) {
        return vec4(pow(color.rgb, vec3(1.0/2.2)), color.a);
      }
  
      void main() {
        // Sample the scene texture
        vec4 tex = texture2D(tDiffuse, vUv);
        
        // STEP 1: Apply ACES Filmic Tone Mapping (if enabled)
        if (enableToneMapping) {
          // Pre-expose before tone mapping
          tex.rgb *= exposure / 0.6;
          tex.rgb = combinedACESFilmicToneMapping(tex.rgb);
        }
        
        // STEP 2: Apply Gamma Correction (if enabled)
        if (enableGammaCorrection) {
          tex = combinedSRGBTransferOETF(tex);
        }
        
        // Initialize output color to the current processed texture
        vec4 outputColor = tex;
        
        // STEP 3: Apply Crop (if enabled)
        if (enableCrop) {
          // Calculate the crop boundaries
          float scaleWidth = 1.0;
          float scaleHeight = 1.0;
    
          if (uCanvasAspectRatio > uTargetAspectRatio) {
            // Canvas is wider than the target aspect ratio, so we add black bars on the sides
            scaleWidth = uTargetAspectRatio / uCanvasAspectRatio;
          } else {
            // Canvas is taller than the target aspect ratio, so we add black bars on top and bottom
            scaleHeight = uCanvasAspectRatio / uTargetAspectRatio;
          }
    
          // Compute the UV bounds of the cropped area
          vec2 cropMin = vec2((1.0 - scaleWidth) / 2.0, (1.0 - scaleHeight) / 2.0);
          vec2 cropMax = vec2((1.0 + scaleWidth) / 2.0, (1.0 + scaleHeight) / 2.0);
    
          // Determine if the current fragment lies within the cropped area
          float insideCrop = step(cropMin.x, vUv.x) * step(vUv.x, cropMax.x) *
                           step(cropMin.y, vUv.y) * step(vUv.y, cropMax.y);
    
          // Color of the black bar, black half transparent
          vec4 blackBar = mix(vec4(0.0, 0.0, 0.0, 1.0), tex, uBlackBarOpacity);
          
          // Mix black for areas outside the crop and content for the valid region
          outputColor = mix(blackBar, tex, insideCrop);
        }
        
        gl_FragColor = outputColor;
      }
    `
  };
  
  export { CombinedPostProcessingShader };