
const mixPassVertexShader = `
out vec2 vUv;
void main() {
	vUv = uv;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`;

const mixPassFragmentShader = `
uniform sampler2D baseTexture;
in vec2 vUv;
out vec4 out_fragColor;
void main() {
	out_fragColor = texture2D( baseTexture, vUv );
}
`;

export { mixPassVertexShader, mixPassFragmentShader }